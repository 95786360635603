@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  font-size : 100%;
  @apply scroll-smooth;
}

html::-webkit-scrollbar{
  @apply  w-1 rounded-sm bg-gray-50;
}
html::-webkit-scrollbar-thumb{
  @apply absolute w-1 rounded-md bg-slate-300;
}
*, *::after{
  @apply transition-all duration-200 appearance-none outline-none;
}
body{
  @apply overflow-x-hidden min-h-screen bg-slate-50;
}
